import React from "react";
import MaxWidth from "../MaxWidth";
import { ReactComponent as EmuseRecordsLogo } from "../../assets/logo.svg";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className="w-full sticky top-0 bg-green">
      <MaxWidth>
        <div className="w-full h-16 flex items-center gap-4 lg:gap-24">
          <Link to={"/"}>
            <EmuseRecordsLogo className="hidden lg:block" />
          </Link>
          <Link to={"/"}>
            <img className="block lg:hidden w-8 " src="/logo.png" alt="" />
          </Link>
          <div className="h-full w-full flex items-center gap-4 font-medium text-md lg:text-base xl:text-xl max-w-sm">
            <Link className="font-medium" to={"/"}>
              Home
            </Link>
            <Link className="font-medium" to={"/bigziq"}>
              Meet Artistes
            </Link>
            {/* <Link to={"#"}>Join Us</Link> */}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
}
