import React from "react";

interface Props {
  color?: string;
  checked?: boolean;
  onClick?: () => void;
}

export default function ColoredRadioBox({
  onClick,
  checked = false,
  color = "#666666",
}: Props) {
  return (
    <div className="flex items-center justify-center h-10 w-10 bg-transparent">
      <div
        onClick={onClick}
        className={`flex items-center justify-center cursor-pointer transition-all ${
          checked
            ? " border-4  bg-transparent h-8 lg:h-10 w-8 lg:w-10 rounded-full"
            : "h-6 lg:h-8 w-6 lg:w-8 rounded-full"
        }`}
        style={{
          backgroundColor: !checked ? color : undefined,
          borderColor: checked ? color : undefined,
        }}
      >
        {checked && (
          <div
            className="h-5 lg:h-6 w-5 lg:w-6 rounded-full cursor-pointer transition-all "
            style={{
              backgroundColor: color,
              borderColor: checked ? color : "",
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
