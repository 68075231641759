import React from "react";
import MaxWidth from "../MaxWidth";
import { Link } from "react-router-dom";

export default function MeetOutArtistes() {
  return (
    <div className="">
      <MaxWidth className="overflow-hidden">
        <div className="space-y-2 py-24">
          <div className="flex justify-between items-start sm:items-center">
            <h3 className="text-3xl sm:text-5xl text-black font-bold">
              Meet our Artistes
            </h3>
            <div className="hidden sm:flex  justify-end">
              <Link
                className={`h-12 px-6 min-w-44 w-max rounded-full flex items-center justify-center uppercase border border-black-secondary text-black`}
                to={"/bigziq"}
              >
                SHOW ALL
              </Link>
            </div>
          </div>
          <div className="flex items-start space-x-2">
            <Link to={"/bigziq"}>
              <div className="bg-white rounded-2xl overflow-hidden px-1 cursor-pointer">
                <div className="h-48 w-48 overflow-hidden">
                  <img src="/bigzIQ.jpeg" alt="bigzIQ" />
                </div>
                <div className="pl-2 mx-auto pb-2 font-medium py-3">BigzIQ</div>
              </div>
            </Link>
          </div>
          <div className="flex sm:hidden w-full justify-end">
            <Link
              className={`h-12 px-6 min-w-44 w-max rounded-full flex items-center justify-center uppercase border border-black-secondary text-black`}
              to={"/bigziq"}
            >
              SHOW ALL
            </Link>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
}
