export const transition400 = {
  transitionProperty: "all",
  transitionTimingFunction: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  transitionDuration: "300ms",
};
export const slideTransition = {
  transitionProperty: "all",
  transitionDuration: "300ms",
};

export const routes = Object.freeze({
  home: "/",
  join_us: "/joinus",
  meet_artiste: "/meet/artiste",
});
