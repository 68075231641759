import React from "react";
import { ReactComponent as Spiral1 } from "../../assets/Spiral1.svg";
import MaxWidth from "../MaxWidth";

export default function LadyWithheadset() {
  return (
    <div className="relative w-full overflow-hidden lg:overflow-visible flex items-center">
      <Spiral1 className="w-full lg:w-[675px] flex items-center" />
      <div className="absolute top-20 sm:top-0 left-0 w-full sm:h-full">
        <MaxWidth className="mt-10 sm:mt-0 sm:h-full">
          <div className="sm:h-full flex">
            <div className="hidden lg:flex flex-1 h-full items-center">
              <img
                className="w-96"
                src="/woman_on_white_headset.png"
                alt="woman_on_white_headset"
              />
            </div>
            <div className="flex-1 flex flex-col space-y-2 justify-center items-start">
              <h3 className="text-4xl lg:text-6xl font-bold tracking-wider">
                Perfect Studio & immersive world
              </h3>
              <p className="text-lg text-gray-500 font-normal">
                Step into the perfect studio where creativity knows no bounds.
                Our immersive world is meticulously crafted to elevate your
                musical experience. From state-of-the-art equipment to a
                captivating atmosphere.
              </p>
            </div>
          </div>
        </MaxWidth>
      </div>
    </div>
  );
}
