import React from "react";
import { ReactComponent as WhiteBig10Logo } from "../../assets/WhiteBig10Logo.svg";
import MaxWidth from "../MaxWidth";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="h-32"></div>
      <footer className="bg-gray-800">
        <MaxWidth>
          <>
            <div className="min-h-52 flex py-10 relative ">
              <div className="h-full flex-1 hidden lg:block">
                <img
                  className="absolute bottom-0 left-0 h-[140%] flex justify-start -ml-24"
                  src="/yellow_hoddie.png"
                  alt=""
                />
              </div>
              <div className="flex-1 text-white space-y-3 flex flex-col justify-center">
                <h3 className="text-4xl sm:text-5xl font-bold py-3 sm:py-6">
                  Be part of a community
                </h3>
                <p className="text-sm text-gray-400 max-w-screen-xs">
                  Join us to get more information about Big10 Record, exclusive
                  first looks at promotions, new contracts and more.
                </p>
                <a
                  className="text-sm text-gray-200 max-w-screen-xs"
                  href="mailto:example@example.com"
                >
                  Big10records2023@gmail.com
                </a>
                {/* <div className="flex items-center flex-col lg:flex-row space-x-2 gap-y-2">
                  <input
                    className="rounded-full h-12 w-full xs:min-w-60 sm:min-w-80 px-3 text-black"
                    placeholder="Your email here"
                  />
                  <Button
                    buttonType="primary"
                    color="white"
                    text="INITIATE REQUEST"
                  />
                </div> */}
              </div>
            </div>
          </>
        </MaxWidth>
        <div className="h-16 bg-black">
          <MaxWidth className="h-full">
            <div className="w-full h-full flex items-center space-x-8">
              <WhiteBig10Logo />
              <div className="flex-1 text-sm font-medium items-center space-x-5">
                <Link className="text-white" to={"/bigziq"}>
                  Meet Artiste
                </Link>
                {/* <Link className="text-white" to={routes.meet_artiste}>
                  Join Us
                </Link> */}
              </div>
            </div>
          </MaxWidth>
        </div>
      </footer>
    </>
  );
}
