import React from "react";
import { ReactComponent as Spiral2 } from "../../assets/Spiral2.svg";
import MaxWidth from "../MaxWidth";

export default function GuyWithHeadset() {
  return (
    <div className="relative w-full overflow-hidden lg:overflow-visible">
      <Spiral2 className="block w-[675px] ml-auto" />
      <div className="absolute top-0 left-0 w-full h-full">
        <MaxWidth className="h-full ">
          <div className="h-full flex">
            <div className="flex-1 flex flex-col space-y-2 justify-center items-start">
              <h3 className="text-5xl sm:text-6xl font-bold tracking-wider">
                24 hours Studio Time
              </h3>
              <p className="text-lg text-gray-500 font-normal">
                Unlock round-the-clock creativity with our studio that opens its
                doors for up to 24 hours. At Big10 Record, the rhythm never
                stops, and inspiration knows no time constraints. Whether it's
                the early morning spark of genius or the late-night wave of
                creativity, our doors are always open.
              </p>
            </div>
            <div className="hidden lg:flex flex-1 h-full items-center">
              <img
                src="/man_on_white_headset.png"
                alt="man_on_white_headset.png"
                className="ml-auto w-full"
              />
            </div>
          </div>
        </MaxWidth>
      </div>
    </div>
  );
}
