import React from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Pages/home";
import Profile from "./Pages/Profile";
import Big10record from "./Pages/big10record";

function App() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/bigziq" element={<Profile />}></Route>
      <Route path="/big10record" element={<Big10record />}></Route>
    </Routes>
  );
}

export default App;
