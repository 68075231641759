import React from "react";
import MaxWidth from "../MaxWidth";
import { ReactComponent as LeftSpiralSVG } from "../../assets/leftSpiral.svg";
import { ReactComponent as RightSpiralSVG } from "../../assets/RightSpiral.svg";

export default function MicEquipment() {
  return (
    <div className="text-center w-full">
      <>
        <MaxWidth>
          <div className="w-full max-w-3xl mx-auto space-y-2">
            <h3 className="block text-4xl sm:text-6xl font-bold tracking-wider w-full text-center">
              Premium State of the Art Equipments{" "}
            </h3>
            <p className="text-lg text-gray-500 font-normal text-center">
              Immerse yourself in a world where every note is crystal clear,
              every beat is powerful, and every moment in the studio is an
              experience of unparalleled quality. At Big10 Record, we redefine
              standard
            </p>
          </div>
        </MaxWidth>
        <div className="w-full relative flex justify-between items-center overflow-x-hidden lg:overflow-visible">
          <LeftSpiralSVG className="w-[250px]" />
          <div className="relative">
            <img className="w-full max-w-xl mx-auto" src="/mic.png" alt="mic" />
            <div className="w-full lg:w-max text-left absolute lg:right-0 top-1/4 rounded-lg py-2 px-2 shadow-lg lg:translate-x-3/4 z-10 bg-white border">
              <h3 className="text-lg font-medium">
                Best Engineering and Sound
              </h3>
              <p className="text-base font-extralight ">
                Designed to handle sounds on the go.
              </p>
            </div>
          </div>
          <RightSpiralSVG className="w-[250px]" />
        </div>
      </>
    </div>
  );
}
