import React, { useCallback, useEffect, useState } from "react";
import Nav from "../../components/Nav";
import { ReactComponent as LeftBlackArrowIcon } from "../../assets/leftBlackArrow.svg";
import { ReactComponent as RightBlackArrowIcon } from "../../assets/rightBlackArrow.svg";
import MaxWidth from "../../components/MaxWidth";
import ColoredRadioBox from "../../components/RadioBox/ColoredRadioBox";
import ProfilePhoto from "../../components/ProfileCarousel/ProfilePhoto";
import LadyWithheadset from "../../components/HomePageSections/LadyWithheadset";
import MicEquipment from "../../components/HomePageSections/MicEquipment";
import GuyWithHeadset from "../../components/HomePageSections/GuyWithHeadset";
import MeetOutArtistes from "../../components/HomePageSections/MeetOutArtistes";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

const heroStyle = {
  background: `url(${require("../../assets/HexPattern.svg")}) no-repeat top right`,
  backgroundSize: "contain",
};

export default function HomePage() {
  const [activeRadioButton, setActiveRadioButton] = useState<number>(0);

  const increaseIndex = useCallback(() => {
    setActiveRadioButton((prev) => {
      if (prev === 4) return 0;
      return prev + 1;
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(increaseIndex, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [increaseIndex]);

  useEffect(() => {
    window.onload = function () {
      window.scrollTo(0, 0); // Scrolls the page to the top when it loads
    };
  }, []);

  return (
    <>
      <div
        style={heroStyle}
        className="relative h-screen bg-green overflow-hidden"
      >
        <Nav />
        <span className="hidden lg:block absolute bottom-0 xl:bottom-full xl:top-0 right-0">
          {/* <HexPattern /> */}
          <img src="/pattern.png" alt="" className="w-[600px]" />
        </span>
        {/* <span className="hidden lg:block absolute bottom-0 right-56 -mt-5 -mr-5"> */}
        {/* <SingerImage className="" /> */}
        <img
          className="hidden lg:block absolute bottom-0 right-56 -mt-5 -mr-5 w-[480px]"
          src="/singer.png"
          alt=""
        />
        {/* </span>ƒ */}
        <div className="h-full w-full flex items-center">
          <MaxWidth className="h-full">
            <>
              <div className="w-full h-full xl:w-7/12 px-2 flex flex-col pt-20 sm:pt-0 sm:justify-center">
                <h1 className=" min-h-36 h-auto xl:h-2/5 text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-black leading-none -tracking-tighter">
                  {activeRadioButton === 0 &&
                    "Transform your passion for music into a journey"}
                  {activeRadioButton === 1 &&
                    "Let your musical passion create a buzz."}
                  {activeRadioButton === 2 &&
                    "Town of fresh beats, where your journey begins."}
                  {activeRadioButton === 3 &&
                    "We provide the unique keys to fresh sounds!"}
                  {activeRadioButton === 4 &&
                    "Let your passion guide you on a journey."}
                </h1>
                <p className="text-base mt-6 text-black-secondary">
                  Timeless language that speaks to the soul
                </p>
                <div className="h-16 xl:h-20 flex gap-2 xl:gap-4 items-center mt-1">
                  <ColoredRadioBox
                    color="#666666"
                    checked={activeRadioButton === 0}
                    onClick={() => setActiveRadioButton(0)}
                  />
                  <ColoredRadioBox
                    color="#FEB554"
                    checked={activeRadioButton === 1}
                    onClick={() => setActiveRadioButton(1)}
                  />
                  <ColoredRadioBox
                    color="#606651"
                    checked={activeRadioButton === 2}
                    onClick={() => setActiveRadioButton(2)}
                  />
                  <ColoredRadioBox
                    color="#EDAB7B"
                    checked={activeRadioButton === 3}
                    onClick={() => setActiveRadioButton(3)}
                  />
                  <ColoredRadioBox
                    color="#C56A72"
                    checked={activeRadioButton === 4}
                    onClick={() => setActiveRadioButton(4)}
                  />
                </div>

                <div className="mt-10 sm:mt-8 flex flex-col sm:flex-row sm:items-center sm:justify-start gap-4">
                  <div className="text-xl font-medium tracking-wide">
                    Soundtrack your career
                  </div>
                  {/* <Button text="Join Us" buttonType="primary" color="black" /> */}
                </div>
              </div>
            </>
          </MaxWidth>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="w-full">
          <MaxWidth>
            <div className="flex items-center space-x-2">
              <LeftBlackArrowIcon className="hidden sm:block w-4 xs:w-max" />
              <div className="flex-1 flex items-center">
                <Link to="/big10record">
                  <ProfilePhoto
                    imgSrc={<img src="/logo.jpeg" className="w-full" alt="" />}
                  />
                </Link>
                <Link to="/bigziq">
                  <ProfilePhoto
                    imgSrc={
                      <img src="/bigzIQ.jpeg" className="w-full" alt="" />
                    }
                  />
                </Link>
              </div>
              <RightBlackArrowIcon className="hidden sm:block w-4 xs:w-max" />
            </div>
          </MaxWidth>
        </div>
        <LadyWithheadset />
        <MicEquipment />
        <GuyWithHeadset />
      </div>
      <div className="bg-gray-100">
        <MeetOutArtistes />
        <Footer />
      </div>
    </>
  );
}
