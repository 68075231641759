import React from "react";
import { transition400 } from "../../constants";

interface Props {
  imgSrc: React.ReactNode;
  activeRoom?: boolean;
}

export default function ProfilePhoto({ imgSrc, activeRoom }: Props) {
  return (
    <div className={`h-36 w-36 xs:h-52 xs:w-52 inline-flex  rounded-[28px]`}>
      <div className="h-full w-full block  rounded-[28px]">
        <div
          className={`mx-auto ${
            !activeRoom && "mt-5"
          } h-[90%] lg:h-[80%] w-[90%] lg:w-[80%] rounded-[28px] shadow-md sm:shadow-lg border-2 borderp[-gray-50 flex items-start justify-center overflow-hidden`}
          style={transition400}
        >
          {imgSrc}
        </div>
        {}
      </div>
    </div>
  );
}
// Accounting - QuickBook 2016 - 2024
// 250400000090 - Accounting 
