import React from "react";
import MaxWidth from "../../components/MaxWidth";
import Nav from "../../components/Nav";

export default function Profile() {
  return (
    <div className="bg-green">
      <Nav />
      <MaxWidth className="py-10 pt-8 min-h-screen">
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-60 h-60 rounded-lg overflow-hidden">
              <img
                className="w-full"
                src="/android-chrome-512x512.png"
                alt=""
                loading="eager"
              />
            </div>
            <div className="flex-1 gap-y-5">
              <h3 className="text-3xl font-bold py-2">VISION:</h3>
              <div className="text-base text-gray-700 pb-2">
                Through our unwavering commitment, we endeavor to establish an
                environment of safety and support, providing a sanctuary where
                the creative potential of young talents can flourish without
                inhibition, empowering them to boldly chase and manifest their
                dreams and aspirations.
              </div>
              <h3 className="text-3xl font-bold py-2">OBJECTIVE:</h3>
              <div className="text-base text-gray-700 pb-2">
                Firstly, We meticulously identify promising young talents across
                diverse fields and we furnish them with comprehensive tools,
                guidance, and opportunities for continual development and
                growth. We illuminate their remarkable talents on a global
                scale, ensuring they receive the recognition and platform they
                deserve to showcase their creativity and innovation to the
                world.
              </div>
            </div>
          </div>
        </>
      </MaxWidth>
    </div>
  );
}
