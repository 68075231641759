import React from "react";
import MaxWidth from "../../components/MaxWidth";
import Nav from "../../components/Nav";

export default function Profile() {
  return (
    <div className="bg-green">
      <Nav />
      <MaxWidth className="py-10 pt-8 min-h-screen">
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-60 h-60 rounded-lg overflow-hidden">
              <img
                className="w-full"
                src="/bigziq2.jpeg"
                alt=""
                loading="eager"
              />
            </div>
            <div className="flex-1 gap-y-5">
              <h3 className="text-3xl font-bold py-2">About BigzIQ:</h3>
              <div className="text-base text-gray-700 pb-2">
                Miracle Aina Simileoluwa Isaac, known by his stage name BigziQ,
                was born in Kwara State, Nigeria, on November 16th. He pursued
                his education in Adult Literacy Education with a minor in Social
                Studies at the University of llorin. Hailing from llorin, Kwara
                State, Nigeria. BigziQ's artistry reflects his diverse
                influences and his commitment to delivering captivating and
                innovative sounds to his audience. BigziQ is passionate and
                dedicated young artiste with a vision to captivate audiences
                through his unique blend of creativity and talent.
              </div>
              <h3 className="text-3xl font-bold py-2">My Artistic Journey:</h3>
              <div className="text-base text-gray-700 pb-2">
                BigziQ was deeply involved in the youth choir group of his
                church, Living Faith. From a young age, BigziQ demonstrated a
                passion for music, writing songs since 2018. In 2024, he
                officially launched his music career with the release of his
                debut single titled "ON CODE." Currently, he is signed to BIG 10
                Records, where he brings a unique blend of Afrobeats, trap, hip
                hop, and drill to his music.
              </div>
            </div>
          </div>

          <div className="flex-1 gap-y-5">
            <h3 className="text-3xl font-bold py-2">Style and Influences:</h3>
            <div className="text-base text-gray-700 pb-2">
              My artistic style is dynamic and ever-evolving, influenced by a
              myriad of sources including Afrobeats, trap, hip hop, and drill to
              his music. I draw inspiration from the world around me, infusing
              elements of creativity into my creations, resulting in a body of
              work that is both diverse and thought-provoking.
            </div>
            <h3 className="text-3xl font-bold py-2">
              Achievements and Recognition:
            </h3>
            <div className="text-base text-gray-700 pb-2">
              In 2024, he officially launched his music career with the release
              of his debut single titled "ON CODE." Currently, he is signed to
              BIG 10 Records, where he brings a unique blend of Afrobeats, trap,
              hip hop, and drill to his music.
            </div>
          </div>
          <div className="flex-1 gap-y-5">
            <h3 className="text-3xl font-bold py-2">Future Aspirations:</h3>
            <div className="text-base text-gray-700 pb-2">
              Looking ahead, I am excited to continue exploring new avenues of
              artistic expression and pushing the limits of my craft. Whether it
              be through collaborations, solo projects, or community
              initiatives, I am committed to making a positive impact through my
              art and leaving a lasting legacy for generations to come.
            </div>
          </div>
        </>
      </MaxWidth>
    </div>
  );
}
