import React from "react";

interface Props {
  children: React.ReactElement;
  className?: string;
}

export default function MaxWidth({ children, className }: Props) {
  return (
    <div className={`w-full max-w-6xl mx-auto px-10 lg:px-5 h-full ${className}`}>
      {children}
    </div>
  );
}
